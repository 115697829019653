@import '../../styles/global';

.LoginModal {
	&__wrapper {
		margin-top: 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		form {
			padding-bottom: 15px;
		}
	}

	&__title {
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 10px;
	}

	&__avatar {
		margin: 3px;
		background-color: $color-orange !important;
	}

	&__extra-options {
		padding-top: 10px;
		display: flex;
		justify-content: space-between;
	}

	&__submit-btn {
		padding: 8px !important;
		margin-bottom: 16px !important;
		font-size: 18px !important;
	}
}
