.Form {
	&__field {
		// padding: 20px;
		// border-bottom: 2px solid #1976d2;
		display: flex;
		flex-direction: column;

		&__error {
			font-size: 11px;
			color: red;
		}
		// &:nth-child(odd) {
		// 	background-color: #e7e7e7;
		// }
		&__divider {
			padding: 5px 20px;
			background-color: #1976d2;
			color: white;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #fff;

			&__content {
				display: flex;
			}

			&__number {
				border-radius: 50%;
				padding: 2px 5px;
				border: 1px solid #fff;
				font-size: 10px;
				background-color: #fff;
				color: #1976d2;
				margin-right: 15px;
			}
		}
	}

	.invalid {
		background-color: #ffe9e9;
	}

	.collapse-all-button {
		position: fixed;
		right: 100px;
		z-index: 2;
		top: 10px;
	}
}
