@import '../../styles/global.scss';

.Navigation {
	position: fixed;
	width: 100%;
	height: 60px;
	background-color: $color-white;
	border-bottom: 1px solid $color-dark;
	padding: 5px;
	display: flex;
	z-index: 2;

	&__logo {
		img {
			height: 31px;
		}
	}
}
