.DragAndDropList__item__wrapper {
	padding: 5px 20px;
	margin: 10px 0;
	border-radius: 3px;
	border: 1px solid lightgray;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover {
		background-color: rgb(232, 232, 232);
	}

	&.dragging {
		background-color: lightcoral;
	}
}
