@import '../../styles/global.scss';

.TaskItem {
	&__actions {
		display: none;
	}
	&__container {
		padding: 0 20px;
		margin: 10px 0;
		border-radius: 3px;
		// box-shadow: 0px 0px 10px -3px lightgray;
		border: 1px solid lightgray;
		&:hover {
			background-color: rgb(232, 232, 232);
		}
		&:hover .TaskItem__actions {
			display: block;
		}
	}

	&__container.dragging {
		background-color: lightcoral;
	}

	&__title {
		margin: 7px;
		text-align: center;

		span:hover {
			color: lightcoral;
			cursor: pointer;
		}
	}
}
